import { useContext } from 'react';
import { AuthContext } from './Components/context/authContext';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './Pages/Home'
import Layout from './Components/Layout';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Article from './Pages/Article';
import ArtEditor from './Pages/ArtEditor';
import Contact from './Pages/Contact';



function App() {
  const { currentUser } = useContext(AuthContext);
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/Login' element={<Login />} />
          <Route path='/Register' element={<Register />} />
          <Route path='/article/:slug' element={<Article />} />
          { currentUser && <Route path='/editor' element={<ArtEditor />} />}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
