import { createContext, useEffect } from "react";
import { useState } from "react";

import { auth } from "../../services/firebase";

export const AuthContext = createContext();

export const AuthContextProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(
        JSON.parse(localStorage.getItem('user')) || null
    );

    const login = (user) => {
        setCurrentUser(user);
    }

    const logout = () => {
        setCurrentUser(null);
        auth.signOut();
    }

    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(currentUser));
    }, [currentUser])

    return (
        <AuthContext.Provider value={{currentUser, login, logout}}>
            {children}
        </AuthContext.Provider>
    )
}