import React from 'react'

import './Contact.css'

const Contact = () => {
  return (
    <div className='contact'>
      <h2>Contact me through :</h2>
      <p><em>Mail</em> : savioluca2@gmail.com</p>
      <p><em>Twitter</em> : <a target="_blank" rel="noopener noreferrer" className='contlink' href='https://twitter.com/Indie_Builder'>https://twitter.com/Indie_Builder</a></p>
    </div>
  )
}

export default Contact