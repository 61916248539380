import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './../services/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useContext } from 'react';
import { AuthContext } from '../Components/context/authContext';

import './Login.css';

const Login = () => {
  const [values, setvalues] = useState({
    email:'',
    password:''
  });
  const [err, seterr] = useState("");

  const navigate = useNavigate();

  const {login} = useContext(AuthContext);

  const handleChange = (e) => {
    setvalues(prev => { return {...prev, [e.target.name]:e.target.value}}) 
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, values.email, values.password)
    .then((userCRedential) => {
      const user = userCRedential.user;
      login(user);
      navigate('/');
    })
    .catch((error) => {
      seterr(error.message);
    });
  }

  return (
    <div className="containerreg">
    <form autoComplete='off'>
        <label>Email</label>
        <input onChange={handleChange} type="email" name="email" id="username" className='input' placeholder='Your email' required/>
        <label>Password</label>
        <input onChange={handleChange} type="password" name="password" id="password" className='input' placeholder='password' autoComplete="off" required/>
        {err ? <p className="error">{err}</p> : ''}
        <input onClick={handleSubmit} type="submit" value="Login" className='btn-submit'/>
    </form>
</div>
  )
}

export default Login