import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { auth, db } from './../services/firebase';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { AuthContext } from '../Components/context/authContext';

import './Register.css'

const Register = () => {
  const [value, setValue] = useState({
    username:"",
    email:"",
    password:"",
    password2:"",
  });
  const [err, seterr] = useState("");
  const navigate = useNavigate();
  const {login} = useContext(AuthContext);

  const handleChange = (e) => {
    setValue(prev => ({...prev, [e.target.name]:e.target.value}))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const userRef = doc(db, "users", value.username);
    createUserWithEmailAndPassword(auth, value.email, value.password)
    .then(async (userCredential) => {
      const user = userCredential.user;
      await updateProfile(user, {
        displayName:value.username
      })
      setDoc(userRef, {
        id: user.uid,
        username:value.username,
        email:value.email,
      });
      login(user);
      navigate("/");
    })
    .catch((error) => {
      seterr(error.message);
    })

  }

  return (
    <div className="containerreg">
        <form action='/' autoComplete='off'>
            <label>Username</label>
            <input onChange={handleChange} type="text" name="username" id="username" className='input' placeholder='Your username' required/>
            <label>Email</label>
            <input onChange={handleChange} type="email" name="email" id="email" className='input' placeholder='abc@exemple.com' autoComplete="off" required/>
            <label>Password</label>
            <input onChange={handleChange} type="password" name="password" id="password" className='input' placeholder='password' autoComplete="off" required/>
            <label>Confirm Password</label>
            <input onChange={handleChange} type="password" name="password2" id="password2" className='input' placeholder='confirm your password' autoComplete="off" required/>
            {err ? <p className="error">{err}</p> : ''}
            <input onClick={handleSubmit} type='submit' value="Register" className='btn-submit'/>
        </form>
    </div>
  )
}

export default Register