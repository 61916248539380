import React, {useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, setDoc, getDoc, getDocs, collection, deleteDoc } from 'firebase/firestore'
import { db, storage } from './../services/firebase'
import { uploadBytes, ref } from 'firebase/storage';
import MDEditor from '@uiw/react-md-editor';
import slugify from 'react-slugify';

import './ArtEditor.css'
import { useEffect } from 'react';

const ArtEditor = () => {
  const location = useLocation();
  const article = location?.state;


  const [value, setValue] = useState(article?.content || '');
  const [title, setTitle] = useState(article?.title || '');
  const [image, setImage] = useState(article?.imageName || '');
  const [imageurl, setImageurl] = useState(article?.image || '');
  const [description, setDescription] = useState(article?.description || '');
  const [err, setErr] = useState('');
  const [counter, setCounter] = useState();
  const [draftTitle, setDraftTitle] = useState('');


  const navigate = useNavigate();

  const handleChange = (e) => {
    setTitle(e.target.value);
  }

  const handleImage = (e) => {
    if(e.target.files){
      setImage(e.target.files[0]);
      setImageurl(URL.createObjectURL(e.target.files[0]));
    }
  }

  useEffect(() => {
    const getCounter = async () => {
      const countRef = doc(db, "counter", "counter");
      const r = await getDoc(countRef)
      setCounter(r.data().counter);
    }
    if(!article) {
        const getDraft = async () => {
        const draftRef = collection(db, "articles_draft");
        const res = await getDocs(draftRef);
        res.forEach((doc) => {
          if(doc.data().length !== 0) {
            const draft = doc.data();
            setTitle(draft.title);
            setDraftTitle(draft.title);
            setValue(draft.content);
            setDescription(draft.description);
          }
        })
      }
      getDraft();
    }
    getCounter();
    return () => {}
  }, [article])


  const handleSubmit = async (e) => {
    e.preventDefault();
    const countRef = doc(db, "counter", "counter");
    const imageRef = ref(storage, 'cover_images/' + image.name);
    const slug = slugify(title);
    if(article?.image !== imageurl) {
      uploadBytes(imageRef, image)
      .catch(error => {
        setErr(error.message);
      });
    }
    const artRef = doc(db, "articles", title);
    const data = {
      id:counter,
      title:title,
      image:'gs://ultimateproductmaker.appspot.com/cover_images/'+image.name,
      content:value,
      description:description,
      slug:slug,
      date: new Date(),
    };
    await setDoc(artRef, data).then(async () => {
      if(!article){
        setDoc(countRef, { counter : counter+1});
      }
      const drafts = await getDocs(collection(db, 'articles_draft'));
      if(!drafts.empty){
        const draftRef = doc(db, 'articles_draft', draftTitle);
        deleteDoc(draftRef);
      }
    navigate('/');
    }).catch(error => {
      setErr(error.message);
    });
  }

  const handleDraft = async (e) => {
    e.preventDefault();
    const imageRef = ref(storage, 'cover_images/' + image.name);
    uploadBytes(imageRef, image)
    .catch(error => {
      setErr(error.message);
    });
    const draftRef = doc(db, "articles_draft", title);
    const data = {
      title:title,
      content:value,
      description:description,
    };
    await setDoc(draftRef, data).then(() => {
      navigate('/');
    }).catch(error => {
      setErr(error.message);
    });
  }

  return (
    <div className="editor-container">
      <label className='label' htmlFor="title">Title</label><br />
      <input type="text" name="title" id="title" className='edit-input' onChange={handleChange} value={title} required/><br />
      <label className='label' htmlFor="description">Description</label><br />
      <input type="text" name="description" id="description" className='edit-input' onChange={e => setDescription(e.target.value)} value={description} required/><br />
      <label htmlFor="cover" className='img-label'>Pick An Image</label>
      <input type="file" name="cover" id="cover" className='img-input' onChange={handleImage} accept='image/*' required/>
      {imageurl && <img className='preview' src={imageurl} alt='preview' />}
      <div data-color-mode='light'>
        <MDEditor highlightEnable={false} height={300} value={value} onChange={setValue} />
      </div>
      {err && <p className='error'>{err}</p>}
      <input onClick={handleSubmit} type='submit' className='btn-submit btneditor'/>
      <input onClick={handleDraft} type='submit' value="Save as a draft" className='btn-submit btneditor'/>
    </div>
  )
}

export default ArtEditor