import React from 'react'
import { Link } from 'react-router-dom'
import twitter from './../images/twitter.png'
import discord from './../images/discord.png'
import './Footer.css'

const Footer = () => {
  return (
    <div className='foot-container'>
        <hr />
        <div className="footnav">
            <Link className='footli link' to='/contact'>Contact me 🤙</Link>
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/Indie_Builder">
                <img className='footli foot-logo' src={twitter} alt="twitter logo" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/AyRw3xBc">
                <img className='footli foot-logo' src={discord} alt="discord logo" />
            </a>
        </div>
    </div>
  )
}

export default Footer