import React, { useEffect, useState, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { doc, collection, query, where , getDocs, setDoc, deleteDoc } from 'firebase/firestore'
import { ref, getDownloadURL } from 'firebase/storage'
import { db, storage } from '../services/firebase'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { AuthContext }  from './../Components/context/authContext'
import {v4 as uuidv4 } from 'uuid'

import './Article.css'

const Article = () => {
  const {currentUser} = useContext(AuthContext);

  const [err, setErr] = useState('');
  const [newComment, setNewComment] = useState('');
  const [article, setArticle] = useState({
    title:'',
    image:'',
    content:'',
    description:'',
    imageName:'',
  })
  const [comments, setComments] = useState([]);
  const [votes, setVotes] = useState([]);

  const {slug} = useParams();
  document.title = article.title;
  

    useEffect(() => {
      const fetchArticle = async () => {
        const artCol = collection(db, "articles")
        const q = query(artCol, where("slug", "==", slug))
        const res = await getDocs(q);
        res.forEach((doc) => {
          const data = doc.data();
          const urlArr = data.image.split('/');
          setArticle(prev => { return {...prev, imageName:urlArr[urlArr.length-1]}});
          getDownloadURL(ref(storage, data.image))
          .then((url) => {
            for (const [key, value] of Object.entries(data)){
              if(key === "image"){
                setArticle(prev => { return {...prev, [key]:url}})
              }else{
                setArticle(prev => { return {...prev, [key]:value}});
              }
            }  
          })
        })
      }
      const fetchComments = async() => {
        const commCol = collection(db, "articles", article.title, "comments");
        const res = await getDocs(commCol);
        res.forEach((doc) => {
          const data = {...doc.data(), id:doc.id}
          setComments(prev => { return [...prev, data]});
        })
      }
      const fetchVotes = async() => {
        const votesCol = collection(db, "articles", article.title, "votes");
        const res = await getDocs(votesCol);
        res.forEach((doc) => {
          setVotes(prev => {return [...prev, doc.data().vote]});
        });
      }
      
      fetchArticle()
      if(article?.title){
        fetchComments();
        fetchVotes();
      }
      return () => {}
    }, [slug, article.title])

    const handleComment = (e) => {
      setNewComment(e.target.value);
    }

    const handleVote = async (flag) => {
      if(currentUser){
        const ref = doc(db, 'articles', article.title, "votes", currentUser.uid);
        if(!votes || (votes?.filter((a) => a.user_id === currentUser.uid).length === 0)) {
          await setDoc(ref, {
            vote : {
              name:currentUser.displayName,
              direction: flag,
              user_id: currentUser.uid
            }
            }).then(() => {
              window.location.reload();
            })
            .catch((err) => {
            alert(err.message);
          })
        } 
        else if ((votes?.filter((a) => a.user_id === currentUser.uid).length !== 0)){
          if((votes?.filter((a) => a.user_id === currentUser.uid)[0].direction === flag)){
            await deleteDoc(ref).then(() => {
                window.location.reload();
              })
              .catch((err) => {
                alert(err.message);
              });
          } else {
            alert("You already voted !");
          }
        }
      }
      else {
        alert("You need to be logged in to vote");
      }
    }
    const handleDelete = async (e) => {
      const comment_id = e.target.parentElement.getAttribute('data-key');
      if(comments[comment_id].user_id === currentUser.uid || currentUser?.uid === process.env.REACT_APP_ADMIN_ID){
        const comm_to_delete = comments[comment_id];
        const commRef = doc(db, 'articles', article.title, 'comments', comm_to_delete.id);
        await deleteDoc(commRef).then(() => {
          window.location.reload();
        }).catch((err) => {
          setErr(err.message);
        })
      } else {
        alert("You can't delete this comment !");
      }
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      const date = new Date();
      if(currentUser){
        const uid = uuidv4();
        const ref = doc(db, 'articles', article.title, 'comments', uid);
        setDoc(ref, 
            {
            name:currentUser.displayName,
            user_id:currentUser.uid,
            comment:newComment,
            date:date
          }
        ).then(() => {
          window.location.reload();
        })
        .catch((err) => {
          setErr(err.message);
        })
      }
      else {
        setErr("You need to be logged in to comment !");
      }
    }
    
  return (
    <>
      <div className='fullarticle centered'>
        <img src={article.image} alt="placeholder" className='artimg'/>
          <h1 className="title">{article.title}</h1>
          <div className='content'>
            <ReactMarkdown>
              {article.content}
            </ReactMarkdown>
          </div>
          {currentUser?.uid === process.env.REACT_APP_ADMIN_ID ? <Link to='/editor' state={{
            title:article.title,
            content:article.content,
            image:article.image, 
            description:article.description,
            imageName:{name:article.imageName},
            }}>
            <button className='editbtn'>
              Edit
            </button>
          </Link> : ""  }
      </div>
      <div className="fullarticle">
        <h2>Comments :</h2>
        { comments.map((comm, id) => (
        <div className="comment" key={id} data-key={id}>
          <h3>{comm.name}</h3>
          <p>{comm.comment}</p>
          <div className="info">{comm.date.toDate().toLocaleDateString()}</div>
          { (currentUser?.displayName === comm.name || currentUser?.uid === process.env.REACT_APP_ADMIN_ID) ? <i onClick={handleDelete} className="fa-regular fa-trash-can delete-comment"></i> : ""}
        </div>
        ))}
        <div className="input-section">
          <label htmlFor="comment">Add a comment 💌</label>
          <textarea onChange={handleComment} name="comment" id="" cols="30" rows="3" className="comment-input" placeholder='type your comment here'></textarea>
          <input onClick={handleSubmit} name='submit' type="submit" className='btn-submit btn-comment' />
          {err && <p className='error'>{err}</p>}
        </div>
      </div>
      <div className="feedback">
        { votes ? 
        <div onClick={() => handleVote(true)} className={`feed ${ (votes.filter((a) => a.user_id === currentUser?.uid && a.direction).length !== 0) ? 'voted' : ''}`}>
          <div className='vote-icon'>👍</div>
          <div className='vote-count'>{votes?.filter(x => x.direction===true).length || 0}</div>
        </div>
         :
         <div onClick={() => handleVote(true)} className='feed'>
          <div className='vote-icon'>👍</div>
          <div className='vote-count'>{votes?.filter(x => x.direction===true).length || 0}</div>
        </div>
        }
        <div onClick={() => handleVote(false)} className={`feed ${(votes.filter((a) => a.user_id === currentUser?.uid && !a.direction).length !== 0) ? 'voted' : ''}`}>
          <div className='vote-icon'>👎</div>
          <div className='vote-count'>{votes?.filter(x => x.direction===false).length || 0}</div>
        </div>
      </div>
    </>
  )
}

export default Article