import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AuthContext } from './context/authContext'

import './Navbar.css'
import placeholder2 from './../images/user.png'

const Navbar = () => {
  const navigate = useNavigate();

  const {currentUser, logout} = useContext(AuthContext);


  const handleClick = () => {
    logout();
    navigate('/');    
  }


  return (
    <nav className='nav-list'>
      <div className="logo-md">
        <Link className='nav-link' to='/'>
        <img className='logo-img' src={process.env.PUBLIC_URL + '/logo.png'} alt="logo" />
          IndieProductMaker
          </Link>
      </div>
      <div className="logo-sm">
        <Link className='nav-link' to='/'>
        <img className='logo-img' src={process.env.PUBLIC_URL + '/logo.png'} alt="logo" />
          IPM
        </Link>
      </div>
      <div className='nav-end'>
        { currentUser?.uid === process.env.REACT_APP_ADMIN_ID ? <Link to='/editor'><button className='nav-button'>Write</button></Link> : "" }
        { currentUser ? <img src={placeholder2} alt="placeholder" className="profilepic"/> : <Link to='/login'><button className='nav-button'>Login</button></Link>}
        { currentUser ? <button onClick={handleClick} className='nav-button'>Logout</button> : <Link to='/register'><button className='nav-button'>Register</button></Link>}
      </div>
    </nav>
  )
}

export default Navbar