import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { doc, collection, getDoc, getDocs, orderBy,query } from 'firebase/firestore'
import { db, storage } from '../services/firebase'
import { ref, getDownloadURL } from 'firebase/storage'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

import './Home.css'
import Pagination from '../Components/Pagination'

const Home = () => {
  const [searchParams, ] = useSearchParams();
  const param = searchParams.get("page") || 1;

  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(param);
  const [counter, setCounter] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  document.title = "Indie Product Maker"


  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const r = await getDoc(doc(db, "counter", "counter"));
      setCounter(r.data().counter);
      const q = query(collection(db, "articles"), orderBy('date', 'desc'))
      const res = await getDocs(q);
      res.forEach((doc) => {
        const data = doc.data();
        let src = '';
        getDownloadURL(ref(storage, data.image))
        .then((url) => {
          src = url;
          const art = {
            title:data.title,
            image:src,
            content:data.content,
            description:data.description,
            slug:data.slug,
            date:data.date.toDate(),
            id:data.id,
          }
          setArticles(prev => {return [...prev, art]});
        });
      })
    }
    fetchData()
    .then(() => {
      setIsLoading(false);
    })
    return () => {}
  }, []);

   const showPage = (i) => {
    setCurrentPage(i);   
    navigate(`/?page=${i}`) 
  }
  

  return (
    <>
    { isLoading && <div className='loader'></div>}
      <div className="container">
        { articles
        .sort((a,b) => {
          return b.date - a.date
        })
        .slice((currentPage-1)*5,(currentPage*5))
        .map((art, id) => (
          <Link className='customlink' to={`/article/${art.slug}`} key={id}>
            <div className="article">
              <img src={art.image} alt="placeholder" className="article-img" />
              <div className="text-content">
                <h1 className='title'>{art.title}</h1>
                <div className="desc-content">
                  <ReactMarkdown>{art.description}</ReactMarkdown>
                </div>
                <div className="info">
                  {art.date.toLocaleDateString()}
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <Pagination totalItems={counter} itemsPerPage={5} pageNavigate={showPage} active={param}/>
    </>
  )
}

export default Home;