import React, {useEffect, useState} from 'react'

import './Pagination.css'

const Pagination = ({totalItems, itemsPerPage, pageNavigate, active}) => {
  const [currentPage, setCurrentPage] = useState(1)
    const pages = [];
    const pageNumber = Math.ceil(totalItems / itemsPerPage);

    for(let i=1; i<=pageNumber; i++) {
        pages.push(i);
    }
    useEffect(() => {
      // eslint-disable-next-line
      if(active != currentPage){
        pageNavigate(active);
        setCurrentPage(active);
      }
    }, [active, currentPage, pageNavigate])
  return (
    <div className='pageContainer'>
        {pages.map((i) => {
          // eslint-disable-next-line
          return (<button onClick={() => pageNavigate(i)} className={`page${active == i ? ' active':''}`} key={i}>{i}</button>)
      })}
    </div>
  )
}

export default Pagination